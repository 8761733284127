.ck.ck-toolbar {
  background: #014a62 !important;
  padding: 6px 15px !important;
}
.ck.ck-toolbar.ck-rounded-corners {
  border-radius: 5px 5px 0 0 !important;
}
.ck.ck-icon * {
  color: white !important;
}
.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover,
.ck.ck-button.ck-on,
a.ck.ck-button.ck-on {
  background: #063341 !important;
}

.ck.ck-toolbar__separator {
  align-self: center !important;
  padding-top: 23px !important;
  width: 2px !important;
  margin-right: 10px !important;
}

/* .ck.ck-icon.ck-button__icon {
  position: absolute !important;
  width: 15px;
  height: 15px;
} */

.ck.ck-button {
  color: white !important;
}

.ck.ck-content {
  height: 250px;
  border: #e5e5e5 1px solid !important;
  background: #fff !important;
}

.ck.ck-editor__editable > .ck-placeholder::before {
  color: #bfbfbf;
}
