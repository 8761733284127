.dropdownHover {
  z-index: 1200;
  max-height: 250px;
  width: 350px;
  margin-left: -150px;
}

.dropdown-logout {
  z-index: 1200;
  max-height: 250px;
  margin-left: -50px;
}

.list-item {
  width: 350px;
}

.avatar-menu {
  z-index: -10;
}