.suggest-list {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
}

.suggest-list:hover {
  background-color: rgb(243, 243, 243);
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  cursor: pointer;
}

.not-found-list {
  padding-top: 10px;
  padding-bottom: 10px;
}

.deep-teal {
  color: #014a62;
  font-weight: bold;
}

.med-gray {
  color: #a9a8a8;
  font-weight: light;
}
